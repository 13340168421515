import clsx from "clsx";
import Spinner from "./Spinner";

const Button = ({
  children,
  loading = false,
  disabled = false,
  block = false,
  size = "default",
  style = "default",
  type = "default",
  className = "",
  iconBefore = "", // icon is used to place an icon before the button text
  iconAfter = "", // iconAfter is used to place an icon after the button text
  onClick,
}) => {
  const handleClick = (e) => {
    if (!disabled && !loading && onClick) {
      onClick(e);
    } else {
      console.log("Missing onClick Event");
    }
  };
  return (
    <button
      onClick={(e) => handleClick(e)}
      className={clsx(
        // defaults
        "inline-flex cursor-pointer items-center justify-center whitespace-nowrap rounded-lg font-semibold shadow transition-all duration-75 focus:z-10 focus:outline-none focus:outline-2 focus:outline-offset-2 focus:outline-blue-600/60 active:shadow-none ",
        className,
        // style: DEFAULT
        {
          "cursor-default bg-white text-gray-500 shadow-none hover:bg-gray-50 hover:text-gray-500 focus:ring-0 focus:ring-offset-0 dark:hover:bg-slate-900":
            style == "default" && type == "default" && loading && !disabled,
        },
        {
          "cursor-not-allowed bg-gray-100 text-gray-500 shadow-none focus:ring-0 focus:ring-offset-0  dark:hover:bg-slate-900":
            style == "default" && type == "default" && loading && disabled,
        },
        {
          "cursor-not-allowed bg-gray-100 text-gray-500 shadow-none focus:ring-0 focus:ring-offset-0 dark:border-gray-600 dark:bg-gray-800 dark:text-gray-500 dark:focus:ring-0 ":
            style == "default" && type == "default" && !loading && disabled,
        },

        // style: PRIMARY
        {
          " bg-slate-900 text-slate-50 hover:bg-slate-900/90 dark:bg-slate-50 dark:text-slate-900 dark:hover:bg-slate-50/90 focus:ring-0 focus:ring-offset-0":
            style == "primary" && !loading && !disabled,
        },
        {
          "bg-slate-900 text-slate-50 hover:bg-slate-900/90 dark:bg-slate-50 dark:text-slate-900 dark:hover:bg-slate-50/90 focus:ring-0 focus:ring-offset-0":
            style == "primary" && loading && !disabled,
        },
        {
          "bg-slate-900 text-slate-50 hover:bg-slate-900/90 dark:bg-slate-50 dark:text-slate-900 dark:hover:bg-slate-50/90 focus:ring-0 focus:ring-offset-0":
            style == "primary" && loading && disabled,
        },
        {
          "bg-slate-900 text-slate-50 hover:bg-slate-900/90 dark:bg-slate-50 dark:text-slate-900 dark:hover:bg-slate-50/90 focus:ring-0 focus:ring-offset-0":
            style == "primary" && !loading && disabled,
        },

        // style: PRIMARY && type: DANGER
        {
          "cursor-default bg-blue-500 text-white shadow-none hover:bg-blue-400 hover:text-white  focus:ring-0":
            loading && style == "primary" && type == "danger" && !disabled,
        },

        { "block w-full": block },
        { "h-12": size == "large" },
        { "h-10": size == "default" },
        { "h-7": size == "small" },
        {
          "border  border-gray-200 bg-white py-2.5 px-5 text-sm text-gray-900 hover:bg-gray-100 hover:text-gray-900 ":
            style == "default" && type == "default",
        },
        {
          " dark:border-gray-400 dark:bg-gray-900 dark:text-gray-300 dark:hover:bg-gray-700 dark:focus:ring-1 dark:focus:ring-offset-1":
            style == "default" && type == "default" && !disabled,
        },
        {
          "border border-gray-200 bg-blue-600 py-2.5 px-5 text-sm text-white  hover:text-white ":
            style == "primary" && type == "default",
        },
        {
          "border border-gray-200 bg-red-600 py-2.5 px-5 text-sm  text-white hover:bg-red-700 hover:text-white ":
            type == "danger",
        },
        {
          "border border-gray-200 bg-red-400 py-2.5 px-5 text-sm cursor-not-allowed text-white hover:bg-red-400 hover:text-white ":
            type == "danger" && disabled,
        }
      )}
    >
      {loading && !disabled && <Spinner style={style} type={type} />}
      {!!iconBefore && (
        <span
          className={clsx(
            { hidden: loading },
            { "mr-2 h-5 w-5": size == "large" },
            { "mr-2 h-4 w-4": size == "default" },
            { "mr-2 h-4 w-4": size == "small" }
          )}
        >
          {iconBefore}
        </span>
      )}


      {children}
      {!!iconAfter && (
        <span
          className={clsx(
            { hidden: loading },
            { "ml-2 h-5 w-5": size == "large" },
            { "ml-2 h-4 w-4": size == "default" },
            { "ml-2 h-4 w-4": size == "small" }
          )}
        >
          {iconAfter}
        </span>
      )}
    </button>
  );
};

export default Button;
